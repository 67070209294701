<template>
  <div class="image-contrast" ref="containerRef" @mousedown="startDragging" @touchstart="startDragging">
    <h3 class="contrast-title">{{ title }}</h3>
    <div class="image-container">
      <div class="base-image">
        <img :src="leftImage" :alt="leftAlt">
      </div>
      <div class="reveal-image" :style="{ clipPath: `inset(0 0 0 ${percentage}%)` }">
        <img :src="rightImage" :alt="rightAlt">
      </div>
      <div class="slider" :style="{ left: `${percentage}%` }">
        <div class="slider-button">↔</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'ImageContrast',
  props: {
    title: { type: String, required: true },
    leftImage: { type: String, required: true },
    rightImage: { type: String, required: true },
    leftAlt: { type: String, default: 'Original Image' },
    rightAlt: { type: String, default: 'Matted Image' },
  },
  setup() {
    const percentage = ref(50);
    const containerRef = ref(null);
    const isDragging = ref(false);

    const updatePercentage = (clientX) => {
      if (containerRef.value) {
        const rect = containerRef.value.getBoundingClientRect();
        const x = clientX - rect.left;
        percentage.value = Math.max(0, Math.min(100, (x / rect.width) * 100));
      }
    };

    const onMouseMove = (event) => {
      if (isDragging.value) {
        updatePercentage(event.clientX);
      }
    };

    const onTouchMove = (event) => {
      if (isDragging.value && event.touches.length > 0) {
        updatePercentage(event.touches[0].clientX);
      }
    };

    const startDragging = (event) => {
      isDragging.value = true;
      if (event.type === 'mousedown') {
        updatePercentage(event.clientX);
      } else if (event.type === 'touchstart' && event.touches.length > 0) {
        updatePercentage(event.touches[0].clientX);
      }
    };

    const stopDragging = () => {
      isDragging.value = false;
    };

    onMounted(() => {
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', stopDragging);
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', stopDragging);
    });

    onUnmounted(() => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', stopDragging);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', stopDragging);
    });

    return {
      percentage,
      containerRef,
      startDragging,
    };
  },
};
</script>

<style scoped>
.image-contrast {
  width: 100%;
  max-width: 750px;
  min-width: 100px;
  margin: 0 auto;
  user-select: none;
}

.contrast-title {
  text-align: center;
  margin-bottom: 0.5rem;
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.base-image,
.reveal-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.base-image img,
.reveal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: white;
  cursor: ew-resize;
}

.slider-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  font-size: 20px;
  color: #333;
}
</style>