<template>
  <div class="comparison-section">
    <h1 class="gradient-title-h1">功能展示</h1>
    <div class="comparison-grid">
      <div v-for="(comparison, index) in comparisons" :key="index" class="comparison-item">
        <h3 class="gradient-title">{{ comparison.title }}</h3>
        <ImageContrast
          :leftImage="comparison.leftImage"
          :rightImage="comparison.rightImage"
          :leftAlt="comparison.leftAlt"
          :rightAlt="comparison.rightAlt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ImageContrast from './ImageContrast.vue';

export default defineComponent({
  name: 'ComparisonGrid',
  components: {
    ImageContrast,
  },
  props: {
    comparisons: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style scoped>
.comparison-section {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px 20px;
}

.comparison-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.comparison-item {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.comparison-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.gradient-title {
  background: linear-gradient(45deg, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 1.5em;
  font-weight: bold;
  padding: 20px;
  margin: 0;
  text-align: center;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .comparison-grid {
    gap: 30px;
  }

  .gradient-title {
    font-size: 1.3em;
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.comparison-section {
  margin-top: 3rem;
  text-align: center;
}

.gradient-title-h1 {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(
    45deg,
    #ff6b6b,
    #feca57,
    #48dbfb,
    #ff9ff3,
    #54a0ff
  );
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 10s ease infinite;
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}
</style>