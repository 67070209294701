<template>
    <nav class="nav-panel" :class="{ 'mobile-nav': isMobile }">
        <div class="wrap">
            <div class="logo"></div>
            <div class="menu-toggle" @click="toggleMobileMenu" :class="{ 'active': showMobileMenu }" v-if="isMobile">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul class="nav-list" :class="{ 'mobile-menu': isMobile, 'show': showMobileMenu }">
                <li class="nav"><a href="https://creatinf.com/">首页</a></li>
                <li class="nav has-submenu">
                    <a @click="toggleSubmenu('feature')">功能
                        <img src="../assets/down.svg" alt="向下箭头" class="arrow-icon"
                            :class="{ 'rotated': submenuStates.feature }">
                    </a>
                    <ul class="submenu" :class="{ 'show': submenuStates.feature }">
                        <li><a href="https://creatinf.com/RemoveBackground/"><img src="../assets/抠图.svg" alt="Icon" class="submenu-icon">一键抠图</a></li>
                        <li><a href="https://creatinf.com/MattingPoints/"><img src="../assets/选点.svg" alt="Icon" class="submenu-icon">选点抠图</a></li>
                        <li><a href="https://creatinf.com/Image_ReFocus/"><img src="../assets/对焦.svg" alt="Icon" class="submenu-icon">图像重对焦</a></li>
                        <!-- <li><a href="https://creatinf.com/VideoMatting/">视频抠像</a></li> -->
                        <!-- <li><a href="https://creatinf.com/Relight/">产品重打光</a></li>
                        <li><a href="https://creatinf.com/MIR/">多模态图像识别</a></li> -->
                        <li><a href="https://creatinf.com/OCR/"><img src="../assets/OCR.svg" alt="Icon" class="submenu-icon">OCR识别</a></li>
                        <li><a href="https://creatinf.com/Super-resolution/"><img src="../assets/无损放大.svg" alt="Icon" class="submenu-icon">图片放大</a></li>
                        <!-- <li><a href="https://creatinf.com/ImageCleaner/">消除笔</a></li>
                        <li><a href="https://creatinf.com/AILogo/">AI生成LOGO</a></li> -->
                        <li><a href="https://creatinf.com/Deblur/"><img src="../assets/去模糊.svg" alt="Icon" class="submenu-icon">图像去模糊</a></li>
                        <li><a href="https://creatinf.com/Image_Convert"><img src="../assets/格式转换.svg" alt="Icon" class="submenu-icon">图像格式修改</a></li>
                        <li><a href="https://creatinf.com/Image_To_SVG"><img src="../assets/位图转矢量图.svg" alt="Icon" class="submenu-icon">位图转矢量图</a></li>

                    </ul>
                </li>
                <li class="nav has-submenu">
                    <a @click="toggleSubmenu('editor')">在线编辑工具
                        <img src="../assets/down.svg" alt="向下箭头" class="arrow-icon"
                            :class="{ 'rotated': submenuStates.editor }">
                    </a>
                    <ul class="submenu" :class="{ 'show': submenuStates.editor }">
                        <!-- <li><a href="https://creatinf.com/Post-Editor/">海报编辑器</a></li> -->
                        <li><a href="https://creatinf.com/Mind_Map/#/"><img src="../assets/思维导图.svg" alt="Icon" class="submenu-icon">思维导图</a></li>
                        <li><a href="https://creatinf.com/PPT-Editor/"><img src="../assets/ppt.svg" alt="Icon" class="submenu-icon">PPT制作</a></li>
                        <li><a href="https://creatinf.com/Text-Editor/"><img src="../assets/文本.svg" alt="Icon" class="submenu-icon">文本编辑器</a></li>
                    </ul>
                </li>
                <!-- <li class="nav"><a href="https://creatinf.com/Chat-AI">ChatAI</a></li> -->
                <li class="nav"><a href="https://creatinf.com/FeedBack/">反馈</a></li>
            </ul>

        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavPanel',
    data() {
        return {
            isMobile: false,
            showMobileMenu: false,
            submenuStates: {
                feature: false,
                editor: false
            }
        }
    },
    mounted() {
        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobile);
    },
    methods: {
        checkMobile() {
            this.isMobile = window.innerWidth <= 768;
        },
        toggleMobileMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        },
        toggleSubmenu(submenu) {
            this.submenuStates[submenu] = !this.submenuStates[submenu];
        }
    }
}
</script>

<style scoped>
.nav-panel {
    -webkit-touch-callout: none;
    user-select: none;
    -webkit-user-drag: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 100;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.wrap {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
}

.logo {
    background: url(../assets/Logo\ copy.png) 10% / contain no-repeat;
    height: 50px;
    width: 200px;
    margin-right: 32px;
    flex: 0 0 auto;
}

.nav-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
}

.nav {
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    flex: 0 0 auto;
}

.nav a {
    text-decoration: none;
    color: #333;
    display: block;
    line-height: 80px;
    font-size: 16px;
    transition: color 0.3s ease;
    white-space: nowrap;
}

.nav:hover>a {
    color: #007bff;
}

.arrow-icon {
    width: 16px;
    height: 13px;
    transition: transform 0.3s ease;
    margin-left: 5px;
}

.arrow-icon.rotated {
    transform: rotate(180deg);
}

.submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    list-style: none;
    padding: 20px;
    margin: 0;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    width: 400px; /* 设置宽度以适应两列,如果需要 */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-top: 3px solid #007bff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    box-sizing: border-box;
}

.submenu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.submenu li {
    flex: 1 1 calc(50% - 20px); /* 设置为两列布局 */
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submenu li a {
    display: flex;
    align-items: center;
    padding: 10px;
    line-height: 1.5;
    transition: background-color 0.3s ease;
    width: 100%;
    text-decoration: none;
    color: #333;
    justify-content: center;
}

.submenu li a:hover {
    background-color: #f8f9fa;
}

.submenu-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

/* 响应式布局 */
@media (max-width: 768px) {
    .submenu {
        flex-direction: column;
        width: 100%;
    }

    .submenu li {
        width: 100%;
    }
}




.menu-toggle {
    display: none;
}

@media (max-width: 768px) {
    .nav-panel {
        height: auto;
    }

    .wrap {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 0;
    }

    .menu-toggle {
        display: flex;
        margin-right: 15px;
        flex-direction: column;
        justify-content: space-around;
        width: 30px;
        height: 25px;
        cursor: pointer;
    }

    .menu-toggle span {
        height: 3px;
        background-color: #333;
        transition: all 0.3s ease;
    }

    .menu-toggle.active span:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .menu-toggle.active span:nth-child(2) {
        opacity: 0;
    }

    .menu-toggle.active span:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -6px);
    }

    .nav-list {
        flex-direction: column;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease;
        background: #fff;
        align-items: flex-start;
    }

    .nav-list.show {
        max-height: 1000px;
    }

    .nav {
        width: 100%;
        padding: 0;
    }

    .nav a {
        padding: 15px 20px;
        line-height: 1.5;
        justify-content: flex-start;
    }

    .has-submenu>a {
        justify-content: space-between;
    }

    .nav-list mobile-menu show li {
        height: 54px;
    }

    ul .submenu {
        padding: 0;
    }


    .submenu {
        position: static;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease;
        opacity: 1;
        visibility: visible;
        transform: none;
        box-shadow: none;
        border: none;
        border-radius: 0;
        display: block;
        padding: 0;
    }

    .submenu.show {
        max-height: 1000px;
    }

    .submenu li {
        width: 100%;
        margin: 0;
    }

    .submenu li a {
        padding-left: 60px;
    }
    .submenu.show li a {
        padding-left: 60px;

    }

    .nav-list.mobile-menu.show .nav>a {
        padding-left: 40px;
        display: flex;
        align-items: center;
    }

    /* 二级菜单项的样式 */
    .nav-list.mobile-menu.show .submenu li a {
        padding-left: 60px;
    }

    /* 确保图标不会被推到右边 */
    .nav-list.mobile-menu.show .nav.has-submenu>a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    /* 调整图标的位置 */
    .nav-list.mobile-menu.show .arrow-icon {
        margin-left: 10px;
        /* 可以根据需要调整这个值 */
    }

    /* 移除可能影响布局的其他样式 */
    .nav-list.mobile-menu.show .nav,
    .nav-list.mobile-menu.show .submenu li {
        padding: 0;
        margin: 0;
    }

    /* 确保链接占满整个宽度 */
    .nav-list.mobile-menu.show .nav>a,
    .nav-list.mobile-menu.show .submenu li a {
        width: 100%;
        box-sizing: border-box;
        padding-left: 60px;
    }

}
</style>