Copy<template>
  <div id="app">
    <NavPanel />
    <ContentSection />
    <section class="comparison-section">
      <ComparisonGrid :comparisons="comparisonData" />
    </section>
    <BottomPart />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import NavPanel from './components/NavPanel.vue'
import ContentSection from './components/ContentSection.vue'
import BottomPart from './components/BottomPart.vue'
import ComparisonGrid from './components/ComparisonGrid.vue'

export default defineComponent({
  name: 'App',
  components: {
    NavPanel,
    ContentSection,
    ComparisonGrid,
    BottomPart
  },
  setup() {
    const comparisonData = [
      {
        title: '抠图效果',
        leftImage: require('@/assets/image/matting_2.png'),
        rightImage: require('@/assets/image/matting_1.png'),
        leftAlt: 'Original Image',
        rightAlt: 'Matted Image',
      },
      {
        title: '消除笔效果',
        leftImage: require('@/assets/image/clean_2.jpg'),
        rightImage: require('@/assets/image/clean_1.jpg'),
        leftAlt: 'Original Image',
        rightAlt: 'Matted Image',
      },
      {
        title
        : '图像放大',
        leftImage: require('@/assets/image/upscale2.jpg'),
        rightImage: require('@/assets/image/upscale1.png'),
        leftAlt: 'Original Image',
        rightAlt: 'Matted Image',
      },
      
      // Add more comparisons here
    ]

    return {
      comparisonData,
    }
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}


</style>