<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-left">
        <br />
        <p>
          <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">冀ICP备2024074295号</a>
        </p>
        
        <br />
        <p>开发者：石家庄舒君科技有限公司</p>
        
      </div>
      <div class="footer-center">
        <div class="footer-links">
          <div class="link-category">
            <h4>抠像处理</h4>
            <ul>
              <li><a href="https://creatinf.com/RemoveBackground/">一键抠图</a></li>
              <li><a href="https://creatinf.com/MattingPoints/">选点抠图</a></li>
              <li><a href="https://creatinf.com/VideoMatting/">视频抠像</a></li>
            
            </ul>
          </div>
          <div class="link-category">
            <h4>图像处理</h4>
            <ul>
              <!-- <li><a href="https://creatinf.com/Relight/">产品重打光</a></li> -->
              <li><a href="https://creatinf.com/Super-resolution/">图片放大</a></li>
              <li><a href="https://creatinf.com/Image_ReFocus/">图像重对焦</a></li>
              <!-- <li><a href="https://creatinf.com/ImageCleaner/">消除笔</a></li> -->
              <li><a href="https://creatinf.com/Deblur/">图像去模糊</a></li>
              <!-- <li><a href="https://creatinf.com/MIR/">多模态图像识别</a></li> -->
              <li><a href="https://creatinf.com/OCR/">OCR识别</a></li>
              <!-- <li><a href="https://creatinf.com/AILogo/">AI生成LOGO</a></li> -->
            
            </ul>
          </div>
          
          <div class="link-category">
            <h4>在线编辑工具</h4>
            <ul>
              <!-- <li><a href="https://creatinf.com/Post-Editor/">海报编辑器</a></li> -->
              <li><a href="https://creatinf.com/Mind_Map/#/">思维导图</a></li>
              <li><a href="https://creatinf.com/PPT-Editor/">PPT制作</a></li>
              <li><a href="https://creatinf.com/Text-Editor/">文本编辑器</a></li>
              <li><a href="https://creatinf.com/Image_Convert">图像格式修改</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-right">
        <br />
        
        <p>Copyright ©{{ currentYear }} 创意无限 保留所有权利</p>
        <p>创意无限测试版本</p>
        <br />
        
        <ul class="version-list">
          <li>
            <a href="https://creatinf.com" target="_blank" rel="noopener noreferrer" class="version-link">v1.0.0-beta</a>
          </li>
          <br />
<!--           <p>如果您想了解更多信息，请点击了解更多查询使用帮助等其他信息内容。如果您想联系我们，可以通过提交反馈方式让我们知道您的需求。</p>
 -->
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BottomPart',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #ffffff;
  color: #34495e;
  font-family: 'Roboto', sans-serif;
  padding: 30px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left, .footer-center, .footer-right {
  flex: 1;
}

.footer-left {
  text-align: left;
}

.footer-center {
  text-align: center;
}

.footer-right {
  text-align: right;
}

.footer-links {
  display: flex;
  justify-content: space-around;
}

.link-category {
  text-align: left;
}

.link-category h4 {
  margin-bottom: 10px;
  color: #2c3e50;
}

.link-category ul {
  list-style-type: none;
  padding: 0;
}

.link-category li {
  margin-bottom: 5px;
}

p {
  margin: 5px 0;
  font-size: 16px;
}

a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #2980b9;
}

.version-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
}

.version-link {
  display: inline-block;
  padding: 5px 10px;
  background-color: #ecf0f1;
  color: #34495e;
  border-radius: 6px;
  font-size: 12px;
  transition: background-color 0.3s, color 0.3s;
}

.version-link:hover {
  background-color: #3498db;
  color: white;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-left, .footer-center, .footer-right {
    text-align: center;
    margin-bottom: 15px;
  }

  .footer-links {
    flex-direction: column;
  }

  .link-category {
    margin-bottom: 20px;
  }
}
</style>